import React from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"

class solutions extends React.Component{

  componentDidMount(){
    navigate(
      "/solutions/materials-costimizer",{}
    )
  }

  render(){
    
    return (
      <Layout>
        
      </Layout>
         );
  }
}


export default solutions